::placeholder {
    font-family: monospace;
    font-style: normal;
    text-align: center;
    color: #838181;
}

.navigationFooter {
    position: absolute;
    bottom: 25px;
    text-align: left;
}

input {
    border: 0;
    outline: 0;
}

input:focus {
    outline: none !important;
}

input[type="text"] {
    font-size: 14px;
    font-family: monospace;
    font-style: normal;
}

.history {
    font-family: Poppins;
    font-style: normal;
    cursor: pointer;
    width: fit-content;

    color: #FFFFFF;
}


.footer{
    margin-top: 1rem;
    padding: 1rem;
    background-color: #0057FF;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
  }

/* .dropdown:active .dropdown-menu {
    display: block;
  } */