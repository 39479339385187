.Tabs {
    font-family: Inter;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
}

ul.nav {
    color: black;
    border: 1.5px solid #E8F0F2;
    border-radius: 2rem;
}

ul.nav li {
    padding: 0.6rem;
    list-style: none;
    text-align: center;
    cursor: pointer;
    transition: all 0.7s;
    border-bottom-left-radius: 2rem;
    border-top-left-radius: 2rem;
    border-bottom-right-radius: 2rem;
    border-top-right-radius: 2rem;
}

.pagination {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.pagination a {
    cursor: default;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #0057FF;
    color: #0057FF;
    margin-left: 10px;
}

.pagination li:not(.disabled) a:hover {
    background-color: bisque;
    cursor: pointer;
}

.pagination li a {
    font-weight: bold;
}

.pagination li.active a {
    color: #fff;
    background: #0057FF;
}

.pagination li.disabled a {
    pointer-events: none;
    color: rgb(198, 197, 202);
    border: 1px solid rgb(198, 197, 202);
}